import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import AuthenticatedRoute from "./AuthenticatedRoute.js";
import UnAuthenticatedRoute from "./UnAuthenticatedRoute.js";
import PortfolioPage from "../Pages/User/PortfolioPage/PortfolioPage.js";
//import Messages from "../Messages/Messages.js";
import InvestPage from "../Pages/Fund/InvestPage/InvestPage.js";
import FundDetailsPage from "../Pages/Fund/FundDetailsPage/FundDetailsPage.js";
import LoginPage from "../Pages/User/LoginPage/LoginPage.js";
import SignupPage from "../Pages/User/SignupPage/SignupPage.js";
import Profile from "../Pages/User/Profile/Profile.js";
import BlogPostPage from "../Pages/User/Learn/BlogPostPage/BlogPostPage.js";
import DocumentsPage from "../Pages/User/DocumentsPage/DocumentsPage.js";
import TransactionsPage from "../Pages/User/TransactionsPage/TransactionsPage.js";
import ForgotPasswordPage from "../Pages/User/ForgotPasswordPage/ForgotPasswordPage.js";
import ConfirmInvestment from "../Pages/User/ConfirmInvestment/ConfirmInvestment.js";
import Messages from "../Pages/User/Messages/Messages.js";
import Learn from "../Pages/User/Learn/Learn.js";
import FAQs from "../Pages/User/FAQs/FAQs.js";
import PageNotFound from "../Pages/404/PageNotFound.js";
import ContactUs from "../Pages/User/ContactUs/ContactUs.js";
import InfiniteInputPage from "../Pages/User/InfiniteInputPage/InfiniteInputPage.js";
import PageLoading from "../Components/GlobalComponents/PageLoading/PageLoading.js";
import TestStepperComponentPage from "../Pages/User/TestStepperComponentPage/TestStepperComponentPage.js";
import InvestmentAccountsPage from "../Pages/User/InvestmentAccountsPage/InvestmentAccountsPage.js";
import AddInvestmentAccount from "../Pages/User/InvestmentAccountsPage/AddInvestmentAccount/AddInvestmentAccount.js";
import AccountDetailsPage from "../Pages/User/InvestmentAccountsPage/AccountDetailsPage/AccountDetailsPage.js";
import InfiniteInputComponent from "../Pages/User/InvestmentAccountsPage/AddInvestmentAccount/InfiniteInputComponent/InfiniteInputComponent.js";
import AccountRelatedPartyInformation from "../Pages/User/InvestmentAccountsPage/AddInvestmentAccount/AccountRelatedPartyInformation/AccountRelatedPartyInformation.js";
import AccountGrantorInformation from "../Pages/User/InvestmentAccountsPage/AddInvestmentAccount/AccountGrantorInformation/AccountGrantorInformation.js";
import ConfirmCode from "../Pages/User/LoginPage/ConfirmCode/ConfirmCode.js";
import SubDocs from "../Pages/User/SubDocs.js";
import CapitalCalls from "../Pages/User/CapitalCalls.js";
import FeederFundSignup from "../Pages/User/FeederFundSignup/FeederFundSignup.js";
import { Route } from "react-router-dom";
import useScreenSize from "../utilities/useScreenSize.js";

export default function Routes({ isLoading }) {
  const isMobile = window.matchMedia(
    "only screen and (max-width: 900px)"
  ).matches;
  // const [mobileDevice, setMobileDevice] = useState(true);

  // const screenSize = useScreenSize();

  // useEffect(() => {
  //   if (screenSize.width < 900) {
  //     setMobileDevice(true);
  //   } else {
  //     setMobileDevice(false);
  //   }
  // }, [screenSize]);

  return !isLoading ? (
    !isMobile ? (
      <Router>
        <Switch>
          <Route exact path="/magiclink/:code">
            <SubDocs />
          </Route>
          {/* <UnAuthenticatedRoute exact path="/magiclink/:code">
          <SubDocs />
        </UnAuthenticatedRoute> */}
          {/* <AuthenticatedRoute exact path="/subdocs/:sigId/:token">
          <SubDocs />
        </AuthenticatedRoute> */}
          <UnAuthenticatedRoute exact path="/">
            <Redirect to="/login" />
          </UnAuthenticatedRoute>
          <UnAuthenticatedRoute exact path="/login">
            <LoginPage />
          </UnAuthenticatedRoute>
          <AuthenticatedRoute exact path="/profile">
            <Profile />
          </AuthenticatedRoute>
          <UnAuthenticatedRoute exact path="/signup/">
            <SignupPage />
          </UnAuthenticatedRoute>
          <UnAuthenticatedRoute exact path="/feeder-signup">
            <FeederFundSignup />
          </UnAuthenticatedRoute>
          <AuthenticatedRoute exact path="/invest">
            <InvestPage />
          </AuthenticatedRoute>
          <AuthenticatedRoute exact path="/invest/:id">
            <FundDetailsPage />
          </AuthenticatedRoute>
          <AuthenticatedRoute exact path="/invest/:id/confirm">
            <ConfirmInvestment />
          </AuthenticatedRoute>
          <AuthenticatedRoute
            exact
            path="/invest/:id/confirm/account_id=:account_id"
          >
            <ConfirmInvestment />
          </AuthenticatedRoute>
          <AuthenticatedRoute exact path="/portfolio">
            <PortfolioPage />
          </AuthenticatedRoute>
          <AuthenticatedRoute exact path="/messages">
            <Messages />
          </AuthenticatedRoute>
          <AuthenticatedRoute exact path="/learn">
            <Learn />
          </AuthenticatedRoute>
          <AuthenticatedRoute exact path="/learn/:id">
            <BlogPostPage />
          </AuthenticatedRoute>
          <AuthenticatedRoute exact path="/faq">
            <FAQs />
          </AuthenticatedRoute>
          <AuthenticatedRoute exact path="/documents">
            <DocumentsPage />
          </AuthenticatedRoute>
          <AuthenticatedRoute exact path="/transactions">
            <TransactionsPage />
          </AuthenticatedRoute>
          <UnAuthenticatedRoute exact path="/forgot-password">
            <ForgotPasswordPage />
          </UnAuthenticatedRoute>
          <AuthenticatedRoute exact path="/contact">
            <ContactUs />
          </AuthenticatedRoute>
          <AuthenticatedRoute exact path="/capital-calls">
            <CapitalCalls />
          </AuthenticatedRoute>
          <AuthenticatedRoute exact path="/investment-accounts">
            <InvestmentAccountsPage />
          </AuthenticatedRoute>
          <AuthenticatedRoute exact path="/investment-accounts/add">
            <AddInvestmentAccount />
          </AuthenticatedRoute>
          <AuthenticatedRoute exact path="/investment-accounts/add/:id">
            <AddInvestmentAccount />
          </AuthenticatedRoute>
          <AuthenticatedRoute
            exact
            path="/investment-accounts/account-details/:id"
          >
            <AccountDetailsPage />
          </AuthenticatedRoute>
          <UnAuthenticatedRoute exact path="/verify-email">
            <ConfirmCode />
          </UnAuthenticatedRoute>
          {/* <AuthenticatedRoute exact path="/infinite">
          <InfiniteInputPage />
        </AuthenticatedRoute> */}
          {/* <AuthenticatedRoute exact path="/test-stepper">
          <TestStepperComponentPage />
        </AuthenticatedRoute> */}
          {/* <AuthenticatedRoute exact path="/infinite">
            <InfiniteInputComponent />
          </AuthenticatedRoute> */}
          <AuthenticatedRoute exact path="/acc-related">
            <AccountRelatedPartyInformation />
          </AuthenticatedRoute>
          {/* <AuthenticatedRoute exact path="/account-grantor/:id">
          <AccountGrantorInformation />
      </AuthenticatedRoute> */}
          <UnAuthenticatedRoute>
            <PageNotFound />
          </UnAuthenticatedRoute>
        </Switch>
      </Router>
    ) : (
      <div className="container">
        <div className="row g-0 justify-content-center">
          <div className="col-12">
            <div className="row g-0 w-100 mt-4 justify-content-center">
              <h5>Sorry, this website is not optimized for mobile devices.</h5>
            </div>
            <div className="row g-0 w-100">
              <span>
                Please try accessing this site through a desktop computer.
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  ) : (
    <PageLoading />
  );
}
