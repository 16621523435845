import React, { useState, useEffect, useMemo } from "react";
import "./Styles/Styles.scss";
import GlobalCarousel from "../../../../../../GlobalComponents/Carousel/Carousel";
import TextInputField from "../../../../../../Components/GlobalComponents/TextInputField/TextInputField";
import MuiButton from "../../../../../../GlobalComponents/Button/MuiButton/MuiButton";
import { verifyMagicCode } from "../../../../../../Api/FeederFundSignupApi";
import { Carousel } from "react-bootstrap";
import AccessCode from "./AccessCode/AccessCode";
import VerifySignupEmail from "./VerifyEmail/VerifyEmail";
import VerifyCitizenship from "./VerifyEmail/VerifyCitizenship.js";

import { AccessCodeContext } from "../../../../../../GlobalContexts/AccesCodeContext";
import TermsOfUse from "./TermsOfUse/TermsOfUse";
import ConfidentialityAgreement from "./ConfidentialityAgreement/ConfidentialityAgreement";
import AccountTypeSelection from "./AccountTypeSelection/AccountTypeSelection";
import AccountQualification from "./AccountQualification/AccountQualification";
import AccountSignupForm from "./AccountSignupForm/AccountSignupForm";
import VerificationCode from "./VerificationCode/VerificationCode";

function SignupSteps() {
  const [accessCodeValue, setAccessCodeValue] = useState(null);
  const [q1Yes, setQ1Yes] = useState(false);


  const [activeSlide, setActiveSlide] = useState(0);

  const value = useMemo(() => {
    return {
      accessCodeValue: accessCodeValue,
      setAccessCodeValue: setAccessCodeValue,
    };
  }, []);

  const [slides, setSlides] = useState([
    <AccessCodeContext.Provider value={value}>
      <AccessCode setActiveSlide={setActiveSlide} />
    </AccessCodeContext.Provider>,
    <AccessCodeContext.Provider value={value}>
      <VerifySignupEmail setActiveSlide={setActiveSlide} />
    </AccessCodeContext.Provider>,
    <AccessCodeContext.Provider value={value}>
    <VerifyCitizenship setActiveSlide={setActiveSlide} q1Yes={q1Yes} setQ1Yes={setQ1Yes} />
  </AccessCodeContext.Provider>,
    <AccessCodeContext.Provider value={value}>
      <TermsOfUse setActiveSlide={setActiveSlide} />
    </AccessCodeContext.Provider>,
    <AccessCodeContext.Provider value={value}>
      <ConfidentialityAgreement setActiveSlide={setActiveSlide} />
    </AccessCodeContext.Provider>,
    <AccessCodeContext.Provider value={value}>
      <AccountTypeSelection setActiveSlide={setActiveSlide} />
    </AccessCodeContext.Provider>,
    <AccessCodeContext.Provider value={value}>
      <AccountQualification setActiveSlide={setActiveSlide} />
    </AccessCodeContext.Provider>,
    <AccessCodeContext.Provider value={value}>
      <AccountSignupForm setActiveSlide={setActiveSlide} />
    </AccessCodeContext.Provider>,
    <AccessCodeContext.Provider value={value}>
      <VerificationCode />
    </AccessCodeContext.Provider>,
  ]);

  return (
    <div className="col-12">
      <div className="row g-0 w-100 mb-3">
        <Carousel
          indicators={false}
          controls={false}
          interval={null}
          activeIndex={activeSlide}
        >
          {slides.map((slide) => (
            <Carousel.Item>
              <div className="col-12 pe-5">
                <div class="row g-0 w-100 add-account-wizard-container">
                  <div class="col-12 add-account-outer-column-padded">
                    {React.cloneElement(slide, {
                      setActiveSlide: setActiveSlide,
                    })}
                  </div>
                </div>
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </div>
  );
}
export default SignupSteps;
